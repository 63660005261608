import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ReactReduxContext, useDispatch } from "react-redux";
import SideBar from "../../layout/Sidebar";
import Header from "../../layout/Header";
import { SharedStateProvider } from "../SharedStateContext";
import { exchangeProductSettingGet } from "../../../actions/exchangeAction";
import { getAllSymbols } from "../../../actions/optionStrategyCommanAction";
import { toast } from "react-toastify";
import { getEmaBacktestStrategy, saveEmaBacktestStrategy } from "../../../actions/emaStrategyAction";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DateRangePicker, Stack } from "rsuite";
import { parseISO } from 'date-fns';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment/moment";
import Swal from "sweetalert2";
import { fyersApiCallCounter } from "../../../actions/backtestAction";
import ExcelLogo from "../../../images/excel.png";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';


function EMAStrategyBacktest() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [dropdownList, setDropdownList] = useState({ setting: [] });
  const [strategySymbols, setStrategySymbols] = useState([]);
  const [symbols, setAllSymbols] = useState([]);
  const [accountName, setAccountName] = useState();
  const [segment, setSegment] = useState('option');
  const [selectedSide, setSelectedSide] = useState("");
  const [errorDateRange, setErrorDateRange] = useState("");
  const [dateRangePicker, setDateRangePicker] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [selectedAccount, setSelectedAccount] = useState("")
  const [expanded, setExpanded] = useState(false);
  const [statistic, setStatistic] = useState(false);
  const [apiCall, setApiCall] = useState([]);

  const switchLabel = { inputProps: { "aria-label": "Switch demo" } };
  const watchOverallProfit = watch("overallProfitVal");
  const watchOverallLoss = watch("overallLossVal");

  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const { afterToday } = DateRangePicker;


  const calculateTotalProfitLoss = (initialData) => {
    let totalProfit = 0;
    let totalLoss = 0;

    if (initialData?.trades) {
      Object.values(initialData?.trades).forEach((trade) => {
        const pnl = trade.pnl || 0;
        if (pnl > 0) {
          totalProfit += pnl;
        } else {
          totalLoss += Math.abs(pnl);
        }
      });
    }

    setStatistic({
      total_profit: totalProfit,
      total_loss: totalLoss,
    })
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleSideChange = (event) => {
    setSelectedSide(event.target.value);
    setValue("call_variation", "")
    setValue("put_variation", "")
  };

  const handleSegment = (event, newAlignment) => {
    if (newAlignment) {
      setSegment(newAlignment);
      setStrategySymbols([])
      setAccountName()
      reset()
    }
  };

  const onSubmit = async (data) => {
    if (dateRangePicker.length === 0) {
      setErrorDateRange("Date Range is required");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to start backtest process`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Process it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const selectedAccount = data.account.split("|");
        const submissionData = {
          ...data,
          account_id: selectedAccount[0],
          account_name: selectedAccount[1],
        };
        const selectedSymbol = submissionData?.symbol?.split("|")
        delete submissionData["account"]
        submissionData["companyName"] = selectedSymbol[0]
        submissionData["symbol"] = selectedSymbol[1]
        submissionData["exchange"] = selectedSymbol[2]
        submissionData["broker"] = accountName
        submissionData["is_delete"] = false
        submissionData["segment"] = segment

        const fromDateTime = new Date(dateRangePicker[0]);
        const toDateTime = new Date(dateRangePicker[1]);

        const fromDate = `${fromDateTime.getFullYear()}-${(
          fromDateTime.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${fromDateTime
            .getDate()
            .toString()
            .padStart(2, "0")}T09:15:00.000Z`;
        const toDate = `${toDateTime.getFullYear()}-${(toDateTime.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${toDateTime
            .getDate()
            .toString()
            .padStart(2, "0")}T15:30:00.000Z`;

        submissionData["from_date"] = fromDate
        submissionData["to_date"] = toDate

        await saveEmaBacktestStrategy(dispatch, submissionData)
          .then((res) => {
            toast.success("Strategy data successfully saved in json file");
          })
          .catch((error) => {
            console.error("Error saving data", error);
          });
      }
    });
  };

  const getSettingData = async () => {
    await exchangeProductSettingGet(dispatch);
    if (store.getState().exchange?.exchangeProductSettingList) {
      const res = store.getState().exchange?.exchangeProductSettingList;
      if (res) {
        setDropdownList(res?.data);
      }
    }
  };


  function getValueByKey(key) {
    for (const obj of dropdownList?.setting) {
      const values = Object.values(obj);
      for (const value of values) {
        if (key in value) {
          return value[key];
        }
      }
    }
    return null; // Return null or any default value if the key is not found
  }


  const handleAccount = (event) => {
    const accountName = event.target.value.split("|")[1];

    setAccountName(getValueByKey(accountName))
    setValue("symbol", "")
    const filteredSymbol = symbols.filter(item => item.account_name === accountName);
    setStrategySymbols(filteredSymbol)
  };

  const getSymbolsData = async () => {
    await getAllSymbols(dispatch).then((res) => {
      setAllSymbols(res?.data?.symbols || [])
    }).catch((error) => {
      toast.error("Symbols list not found")
    })
  };

  const getEmaBacktestStrategyData = async () => {
    await getEmaBacktestStrategy().then((backtest_data) => {
      var from_date = new Date(backtest_data?.from_date);
      var to_date = new Date(backtest_data?.to_date);
      from_date = parseISO(from_date.toISOString());
      to_date = parseISO(to_date.toISOString());
      setDateRangePicker([from_date, to_date])

      // try this code with useState
      setValue("account", `${backtest_data?.account_id}|${backtest_data?.account_name}`)
      setSelectedAccount(`${backtest_data?.account_id}|${backtest_data?.account_name}`)
      setInitialData(backtest_data || {})
      setSegment(backtest_data?.segment)
      setSelectedSide(backtest_data?.side)

      Object?.keys(backtest_data).forEach((key) => {
        setValue(key, backtest_data[key]);
      });

    }).catch((error) => { })
  }


  const handleRefresh = () => {
    getEmaBacktestStrategyData();
    getApiCall();
  }


  const getApiCall = async () => {
    await fyersApiCallCounter(dispatch);
    if (store.getState()?.backtest?.fyers_api_call_counter) {
      setApiCall(store.getState()?.backtest?.fyers_api_call_counter?.data);
    }
  };


  // Function to convert data to Excel format and initiate download
  const convertDataToExcel = () => {
    // Flatten data into rows
    const flattenedData = [];
    Object.keys(initialData?.trades).forEach((date) => {
      const { pnl, data } = initialData?.trades[date];
      data.forEach((trade) => {
        flattenedData.push({ date, pnl, ...trade });
      });
    });

    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Trades");

    // Generate Excel file and download
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "Trades.xlsx");

  };


  useEffect(() => {
    getSettingData();
    getSymbolsData();
    getEmaBacktestStrategyData();
    getApiCall();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialData) {
      calculateTotalProfitLoss(initialData)
    }
  }, [initialData])

  useEffect(() => {
    if (selectedAccount && symbols) {
      const accountName = selectedAccount?.split("|")[1];
      setAccountName(getValueByKey(accountName))
      const filteredSymbol = symbols?.filter(item => item.account_name === accountName);
      setStrategySymbols(filteredSymbol)
    }
    // eslint-disable-next-line
  }, [symbols, selectedAccount])

  useEffect(() => {
    if (selectedAccount && symbols) {
      const accountName = selectedAccount?.split("|")[1];
      setAccountName(getValueByKey(accountName))
      const filteredSymbol = symbols?.filter(item => item.account_name === accountName);
      setStrategySymbols(filteredSymbol)
    }
    // eslint-disable-next-line
  }, [symbols, selectedAccount])

  useEffect(() => {
    if (initialData && strategySymbols) {
      setValue("symbol", `${initialData?.companyName}|${initialData?.symbol}|${initialData?.exchange}`);
    }
  }, [initialData, strategySymbols, setValue]);


  return (
    <div className="Main">
      <SharedStateProvider>
        <SideBar />
        <Header />
      </SharedStateProvider>
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <ul className="breadcrumb p-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/ema-strategy">
                            EMA Strategy
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/ema-backtest" style={{ fontWeight: 600 }}>
                            EMA Backtest
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">

                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <div
                        style={{ marginTop: "6px", display: "flex" }}
                        className="pb-3"
                      >
                        {apiCall
                          ? apiCall?.length !== 0
                            ? "API CALL DETAIL :-"
                            : null
                          : null}
                        {apiCall?.map((data, index) => (
                          <div key={index}>
                            <span className="ml-2">{data?.account_name}</span>{" "}
                            - <span className="mr-2">{data?.api_count}</span>
                            {index !== apiCall?.length - 1 && (
                              <span>|</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body pt-3 pb-1">
                      <div className="text-center mb-4">
                        <ToggleButtonGroup
                          color="primary"
                          value={segment}
                          exclusive
                          onChange={handleSegment}
                          aria-label="Segment"
                        >
                          <ToggleButton value="option">Option</ToggleButton>
                          <ToggleButton value="future">Future</ToggleButton>
                        </ToggleButtonGroup>
                        <button type="submit" className="btn btn-outline-secondary ml-5 mt-1" onClick={handleRefresh}>
                          Refresh
                        </button>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-3 customDateRangeStyle pr-0">
                            <label>Date Range*</label>
                            <Stack>
                              <DateRangePicker
                                size="lg"
                                value={dateRangePicker}
                                onChange={(value) => {
                                  setDateRangePicker(value);
                                  setErrorDateRange("");
                                }}
                                disabledDate={afterToday()}
                                placeholder="Select..."
                              />
                            </Stack>
                            {errorDateRange && (
                              <div className="validationAlert">
                                Date Range is required
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>ICICI Account*</label>
                            <select
                              className="form-control"
                              {...register("account", { required: true })}
                              defaultValue=""
                              onChange={handleAccount}
                            >
                              <option value="" disabled>Select...</option>
                              {dropdownList?.setting?.map((setting) => {
                                const id = Object.keys(setting)[0];
                                const nestedObject = setting[id];
                                const key = Object.keys(nestedObject)[0];
                                const value = Object.values(nestedObject)[0];
                                return (
                                  value === "ICICI" ?
                                    <option key={id} value={`${id}|${key}`}>
                                      {key}
                                    </option>
                                    : null
                                );
                              })}
                            </select>
                            {errors.account && (
                              <span className="validationAlert">Account is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-3 pr-0">
                            <label>Symbol*</label>
                            <select
                              className="form-control"
                              {...register("symbol", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>Select...</option>
                              {strategySymbols?.map((data, index) => {
                                return accountName === "ANGEL" ? (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.companyName}</option>
                                ) : (
                                  <option value={`${data?.companyName}|${data?.shortName}|${data?.marketType}`} key={index}>{data?.shortName}</option>
                                )
                              })}
                            </select>
                            {errors.symbol && (
                              <span className="validationAlert">Symbol is required</span>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Quantity*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Quantity"
                              {...register(`quantity`, {
                                required: "Quantity is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.quantity && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.quantity?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Interval*</label>
                            <select
                              className="form-control"
                              {...register("interval", { required: true })}
                              defaultValue=""
                            >
                              <option value="" disabled>Select...</option>
                              <option value="5S">5second</option>
                              <option value="10S">10second</option>
                              <option value="15S">15second</option>
                              <option value="30S">30second</option>
                              <option value="1">1minute</option>
                              <option value="3">3minute</option>
                              <option value="5">5minute</option>
                              <option value="15">15minute</option>
                            </select>
                            {errors.interval && (
                              <span className="validationAlert">Interval is required</span>
                            )}
                          </div>
                        </div>

                        <div className="row col-md-12 pr-0">
                          <div className="form-group col-md-2 pr-0">
                            <label>Lower EMA Value*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Lower ema value"
                              {...register(`lower_ema_value`, {
                                required: "Lower ema value is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.lower_ema_value && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.lower_ema_value?.message}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-2 pr-0">
                            <label>Upper EMA*</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Upper ema value"
                              {...register(`upper_ema_value`, {
                                required: "Upper ema value is required",
                                valueAsNumber: true, // Converts the input to a number
                                validate: (value) => Number.isInteger(value) || "Only integers are allowed"
                              })}
                              onKeyPress={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === 'e') {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errors?.upper_ema_value && (
                              <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                {errors?.upper_ema_value?.message}
                              </div>
                            )}
                          </div>

                          {segment === "option" ?
                            <div className="form-group col-md-2 pr-0">
                              <label>Expiry*</label>
                              <select
                                className="form-control"
                                {...register("expiry", { required: true })}
                                defaultValue=""
                              >
                                <option value="" disabled>Select...</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                              </select>
                              {errors.expiry && (
                                <span className="validationAlert">Expiry is required</span>
                              )}
                            </div>
                            : null}


                          {segment === "option" ?
                            <>
                              <div className="form-group col-md-2 pr-0">
                                <label>Side*</label>
                                <select
                                  className="form-control"
                                  {...register("side", { required: true })}
                                  defaultValue=""
                                  onChange={handleSideChange}
                                >
                                  <option value="" disabled>Select...</option>
                                  <option value="call">Call</option>
                                  <option value="put">Put</option>
                                  <option value="both">Both</option>
                                </select>
                                {errors.side && (
                                  <span className="validationAlert">Side is required</span>
                                )}
                              </div>

                              {selectedSide === 'call' || selectedSide === 'both' ? (
                                <div className="form-group col-md-2 pr-0">
                                  <label>Call Variation*</label>
                                  <select
                                    className="form-control"
                                    {...register("call_variation", { required: selectedSide === 'call' || selectedSide === 'both' })}
                                    defaultValue=""
                                  >
                                    <option value="" disabled>Select...</option>
                                    <option value="1000_ITM">1000 ITM</option>
                                    <option value="900_ITM">900 ITM</option>
                                    <option value="800_ITM">800 ITM</option>
                                    <option value="700_ITM">700 ITM</option>
                                    <option value="600_ITM">600 ITM</option>
                                    <option value="500_ITM">500 ITM</option>
                                    <option value="400_ITM">400 ITM</option>
                                    <option value="300_ITM">300 ITM</option>
                                    <option value="200_ITM">200 ITM</option>
                                    <option value="100_ITM">100 ITM</option>
                                    <option value="ATM">ATM</option>
                                    <option value="100_OTM">100 OTM</option>
                                    <option value="200_OTM">200 OTM</option>
                                    <option value="300_OTM">300 OTM</option>
                                    <option value="400_OTM">400 OTM</option>
                                    <option value="500_OTM">500 OTM</option>
                                    <option value="600_OTM">600 OTM</option>
                                    <option value="700_OTM">700 OTM</option>
                                    <option value="800_OTM">800 OTM</option>
                                    <option value="900_OTM">900 OTM</option>
                                    <option value="1000_OTM">1000 OTM</option>
                                  </select>
                                  {errors.callVariation && (
                                    <span className="validationAlert">Call Variation is required</span>
                                  )}
                                </div>
                              ) : null}

                              {selectedSide === 'put' || selectedSide === 'both' ? (
                                <div className="form-group col-md-2 pr-0">
                                  <label>Put Variation*</label>
                                  <select
                                    className="form-control"
                                    {...register("put_variation", { required: selectedSide === 'put' || selectedSide === 'both' })}
                                    defaultValue=""
                                  >
                                    <option value="" disabled>Select...</option>
                                    <option value="1000_ITM">1000 ITM</option>
                                    <option value="900_ITM">900 ITM</option>
                                    <option value="800_ITM">800 ITM</option>
                                    <option value="700_ITM">700 ITM</option>
                                    <option value="600_ITM">600 ITM</option>
                                    <option value="500_ITM">500 ITM</option>
                                    <option value="400_ITM">400 ITM</option>
                                    <option value="300_ITM">300 ITM</option>
                                    <option value="200_ITM">200 ITM</option>
                                    <option value="100_ITM">100 ITM</option>
                                    <option value="ATM">ATM</option>
                                    <option value="100_OTM">100 OTM</option>
                                    <option value="200_OTM">200 OTM</option>
                                    <option value="300_OTM">300 OTM</option>
                                    <option value="400_OTM">400 OTM</option>
                                    <option value="500_OTM">500 OTM</option>
                                    <option value="600_OTM">600 OTM</option>
                                    <option value="700_OTM">700 OTM</option>
                                    <option value="800_OTM">800 OTM</option>
                                    <option value="900_OTM">900 OTM</option>
                                    <option value="1000_OTM">1000 OTM</option>
                                  </select>
                                  {errors.putVariation && (
                                    <span className="validationAlert">Put Variation is required</span>
                                  )}
                                </div>
                              ) : null}
                            </>
                            : null}

                          <div className="row col-md-12">
                            <div className="form-group col-md-2 pr-0">
                              <label className="mr-2">Profit</label>
                              <label>(%</label>
                              <Switch
                                {...switchLabel}
                                {...register("overallProfitVal", { required: false })}
                                checked={watchOverallProfit ? true : false}
                                onChange={(event) => {
                                  setValue(
                                    "overallProfitVal",
                                    event.target.checked ? true : false
                                  );
                                }}
                                className="switchGapStyle"
                              />
                              <label>Val)</label>
                              <Tooltip title="entry price(200) + (overall profit(200) / (quantity * lotsize))">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Overall Profit"
                                {...register(`overall_profit`, {
                                  required: false,
                                })}
                              />
                              {errors?.overall_profit && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.overall_profit?.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-md-2 pr-0">
                              <label className="mr-2">Loss</label>
                              <label>(%</label>
                              <Switch
                                {...switchLabel}
                                {...register("overallLossVal", { required: false })}
                                checked={watchOverallLoss ? true : false}
                                onChange={(event) => {
                                  setValue(
                                    "overallLossVal",
                                    event.target.checked ? true : false
                                  );
                                }}
                                className="switchGapStyle"
                              />
                              <label>Val)</label>
                              <Tooltip title="entry price(200) - (overall loss(200) / (quantity * lotsize))">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Overall Loss"
                                {...register(`overall_loss`, {
                                  required: false,
                                })}
                              />
                              {errors?.overall_loss && (
                                <div className="validationAlert" style={{ margin: "10px 0px" }}>
                                  {errors?.overall_loss?.message}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mt-2 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary ml-3 px-4"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body p-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ width: "90%" }}>
                        Total Profit: {statistic?.total_profit?.toFixed(2)} | Total Loss: {statistic?.total_loss?.toFixed(2)} | Total Trade: {initialData?.total_trade}
                      </div>
                      <div style={{ width: "7%" }}>
                        <button
                          onClick={convertDataToExcel}
                          className="btn mr"
                          aria-label="delete"
                          style={{ border: "1px solid darkgreen", background: "transparent", padding: "5px" }}
                        >
                          <img src={ExcelLogo} alt="excelLogo" className="w-50" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-body">
                <div className="page-wrapper">
                  <div>
                    {Object.keys(initialData?.trades || {}).map((date) => (
                      <Accordion key={date} expanded={expanded === date} onChange={handleChange(date)} className="p-2">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${date}-content`}
                          id={`${date}-header`}
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }}>{moment(date).format("MMM DD YYYY")}</Typography>
                          <Typography sx={{ color: 'text.secondary', marginLeft: "auto" }}>Profit/Loss:
                            <span
                              className={`ml-2 ${initialData?.trades[date].pnl >= 0 ? 'trade-profit-badge' : initialData?.trades[date].pnl < 0 ? 'trade-loss-badge' : ''}`}
                            >
                              {initialData?.trades[date].pnl !== 0 ? initialData?.trades[date].pnl.toFixed(2) : '0.00'}
                            </span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer component={Paper}>
                            <Table aria-label="trade table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>ID</TableCell>
                                  <TableCell>Symbol</TableCell>
                                  <TableCell>Max High</TableCell>
                                  <TableCell>Min Low</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>Entry Price</TableCell>
                                  <TableCell>Entry Time</TableCell>
                                  <TableCell>Exit Price</TableCell>
                                  <TableCell>Exit Time</TableCell>
                                  <TableCell>Transaction</TableCell>
                                  <TableCell>P&L</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {initialData?.trades[date].data.map((trade, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{trade.symbol}</TableCell>
                                    <TableCell>{trade.max_high}</TableCell>
                                    <TableCell>{trade.min_low}</TableCell>
                                    <TableCell>{trade.quantity}</TableCell>
                                    <TableCell>{trade.entry_price}</TableCell>
                                    <TableCell>{moment.utc(trade.entry_time).format("MMM DD YYYY h:mm:ss A")}</TableCell>
                                    <TableCell>{trade.exit_price}</TableCell>
                                    <TableCell>{moment.utc(trade.exit_time).format("MMM DD YYYY h:mm:ss A")}</TableCell>
                                    <TableCell>{trade.transaction}</TableCell>
                                    <TableCell>
                                      <span className={trade.profit > 0 ? 'trade-table-profit-badge' : trade.profit < 0 ? 'trade-table-loss-badge' : ''}>
                                        {trade.profit !== 0 ? trade.profit : '0'}
                                      </span>
                                    </TableCell>

                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EMAStrategyBacktest;
